<template>
  <top-bar />
  <router-view />
  <main-nav />
</template>
<script>
import TopBar from '@/components/TopBar.vue'
import MainNav from '@/components/MainNav.vue'
export default {
    components:{
        TopBar,
        MainNav,
    },
}
</script>