<template>
	<div class="float-nav">
		<div class="float-nav__links">
			<router-link v-if="isVendor"  :to="{name: 'Home'}" class="float-nav__link-container">
				<div class="float-nav__link">
					<img class="float-nav__image" src="@/assets/images/dashboard.svg" alt="">
					<div class="float-nav__label">dashboard</div> 
				</div>
			</router-link>
			<router-link :to="{ name: 'Branches'}" class="float-nav__link-container">
				<div class="float-nav__link" :class="{'float-nav__link--small-text':!isVendor}">
					<img class="float-nav__image" src="@/assets/images/agency.svg" alt="">
					<div class="float-nav__label">Home</div> 
				</div>
			</router-link>
			<router-link v-if="isVendor" :to="{name:'user-roles'}" class="float-nav__link-container">
				<div class="float-nav__link">
					<img class="float-nav__image" src="@/assets/images/settings.svg" alt="">
					<div class="float-nav__label">Settings</div> 
				</div>
			</router-link>
			<a v-else>
				<div class="float-nav__link" style="opacity:.5">
					<img class="float-nav__image" src="@/assets/images/settings.svg" alt="">
					<div class="float-nav__label">Settings</div> 
				</div>
			</a>
		</div>	
	</div>
</template>

<script>
export default {
  name: 'FloatNav',
  computed:{
		isVendor(){ return this.$store.state.user.user_type === 'Vendor' },
		branchId(){ return this.$store.state.branchId },
	}
}
</script>

