<template>
  <nav>
    <div class="top-nav">
      
      <div class="top-nav__container top-nav__container--logo">
        <logo />
      </div>
      <div class="top-nav__container top-nav__container--search">
        <!-- <img class="top-nav__search-image" src="../assets/images/014-search.svg" alt="">
        <input class="top-nav__search-input" type="text" name="search" placeholder="search"> -->
      </div>
      
      <div class="top-nav__container top-nav__container--profile">
        <div class="top-nav__profile"><img class="top-nav__profile-image" img src="../assets/images/profile.png" alt=""></div>
        <div class="top-nav__profile-name"> {{ username }}</div>
      </div>
      <div class="top-nav__container top-nav__container--notificaton">
        <div class="top-nav__notification">
          <img class="top-nav__notification-image" src="../assets/images/bell.svg" alt="">
        </div>
        <span class="top-nav__tooltip">notification</span>
      </div>
      <div class="top-nav__container">
        <div class="top-nav__logout" @click="logOut">
          <img class="top-nav__logout-image" src="@/assets/images/logout.svg" alt="" srcset="">
        </div>
        <span class="top-nav__tooltip">logout</span>
      </div>
	</div>
  </nav>
</template>

<script>
// import Menu from '@/components/MobileMenu.vue'
import { mapState } from 'vuex'
import Logo from '@/components/Logo.vue'
export default {
  name: 'TopNav',
  
  components:{
    Logo,
  },
  methods:{
    logOut(){
      this.$router.push({name:'Logout'})
    }
  },
  computed:{
    ...mapState({
      username: state => state.user.username
    })
  } 
}
</script>
