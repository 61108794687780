<template>
  <button class="menu" @click="setMobileSideNav" :class="{'menu--logout': onLogoutPage }">
    <img v-if="mobileSideNav" src="@/assets/images/menu.png" alt="" class="menu__image">
    <img v-else src="@/assets/images/close.svg" alt="" class="menu__image">
  </button>
  <div class="logo" :class="{'logo--mobile-sidenav': !mobileSideNav }">
      <img class="logo__icon" src="@/assets/images/logo.png" alt="">
      <img class="logo__text" src="@/assets/images/logo-text.jpeg" alt="">
  </div> 
</template>

<script>
export default {
  computed:{
    mobileSideNav(){
      return this.$store.state.mobileSideNav
    },
    onLogoutPage(){
      return this.$route.name === 'Logout' || this.$route.name === 'Login' 
    }
  },
  methods:{
    setMobileSideNav(){
      this.$store.commit({type:'setMobileSideNav',mobileSideNav:!this.mobileSideNav})
    }
  }
}
</script>
